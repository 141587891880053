<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="title"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <Form :column="column" :label-width="100" :query="query" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">{{ translateTitle('取消') }}</el-button>
      <el-button :loading="loading" type="primary" @click="onSubmit">
        {{ translateTitle('确定') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { userMute, bannedPrivate, batchUserBanned } from '@/api/user'
  import Form from '@/components/Form'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        title: null,
        dialogVisible: false,
        loading: false,
        is_UID: false,
        row: {},
        query: {
          user_ids: null,
          user_id: null,
          time: null,
          reason: null,
          is_inform: null,
          remark: null,
        },
        muteTypeList: Object.freeze([
          {
            id: 0,
            label: '永久',
          },
          {
            id: 1,
            label: '一天',
          },
          {
            id: 3,
            label: '三天',
          },
          {
            id: 7,
            label: '一周',
          },
          {
            id: 30,
            label: '一个月',
          },
          {
            id: 180,
            label: '半年',
          },
        ]),
        // 是否
        whetherList: Object.freeze([
          {
            id: 1,
            label: '是',
          },
          {
            id: -1,
            label: '否',
          },
        ]),
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      column() {
        return [
          {
            label: '禁言时间',
            prop: 'time',
            type: 'select',
            clearable: false,
            list: this.muteTypeList,
          },
          {
            label: '操作理由',
            prop: 'reason',
            type: 'select',
            optionValue: 'id',
            optionLabel: 'name',
            clearable: false,
            list: this.config.action_reasons,
          },
          {
            label: '消息通知',
            prop: 'is_inform',
            type: 'radio',
            list: this.whetherList,
          },
          {
            label: '消息备注',
            type: 'textarea',
            prop: 'remark',
            tips: '若开启了消息通知，填写的备注内容将会替换上方选择的不通过原因，发送给用户',
          },
          {
            label: 'UID',
            prop: 'user_ids',
            type: 'textarea',
            tips: 'UID用英文逗号隔开',
            isHide: !this.is_UID,
          },
        ]
      },
    },
    methods: {
      translateTitle,
      handleOpen(title, row) {
        this.title = title
        this.row = row || {}
        this.$nextTick(() => {
          if (row) {
            this.query.time = 1
            this.query.reason = this.config.action_reasons[0].id
            this.is_UID = false
          } else {
            this.query.time = 0
            this.is_UID = true
          }
          this.query.is_inform = 1
        })
        this.dialogVisible = true
      },
      // 提交
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        let params = Object.assign({}, this.query)
        let res
        if (!this.is_UID) {
          params.user_id = this.row.user_id
          delete params.user_ids
          if (this.title == this.translateTitle('禁言')) {
            res = await userMute(params)
          } else if (this.title == this.translateTitle('禁止私聊')) {
            res = await bannedPrivate(params)
          }
        } else {
          delete params.user_id
          res = await batchUserBanned(params)
        }
        this.loading = false
        if (res.success) {
          this.cancel()
          this.$emit('getTableData')
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg)
        }
      },
      handleClose(done) {
        done()
        this.cancel()
      },
      // 取消
      cancel() {
        this.dialogVisible = false
        setTimeout(() => {
          for (let key in this.query) {
            this.query[key] = null
          }
        }, 500)
      },
    },
  }
</script>
