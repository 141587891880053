var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        "before-close": _vm.handleClose,
        title: _vm.translateTitle(_vm.title),
        visible: _vm.dialogVisible,
        width: "40%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "username" },
        [
          _c("el-image", {
            staticClass: "userImg",
            attrs: { "preview-src-list": [_vm.user.head], src: _vm.user.head },
          }),
          _c("span", [_vm._v(_vm._s(_vm.user.name))]),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { column: 2 },
        },
        [
          _vm._l(_vm.infoColumnList, function (item, index) {
            return [
              item.prop !== "tags"
                ? _c(
                    "el-descriptions-item",
                    {
                      key: index,
                      attrs: { label: _vm.translateTitle(item.label) },
                    },
                    [_vm._v(" " + _vm._s(_vm.user[item.prop]) + " ")]
                  )
                : _c(
                    "el-descriptions-item",
                    {
                      key: index,
                      attrs: { label: _vm.translateTitle(item.label) },
                    },
                    _vm._l(_vm.user[item.prop], function (el, idx) {
                      return _c("el-tag", { key: idx }, [
                        _vm._v(" " + _vm._s(el.name) + " "),
                      ])
                    }),
                    1
                  ),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.user.role === 0
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.handleUserMute(_vm.translateTitle("禁言"))
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("禁言")) + " ")]
              )
            : _vm._e(),
          _vm.user.role === 1
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.handleCancelMute(_vm.translateTitle("取消禁言"))
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("取消禁言")) + " ")]
              )
            : _vm._e(),
          _vm.chat === 0
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.handleUserMute(_vm.translateTitle("禁止私聊"))
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("禁止私聊")) + " ")]
              )
            : _vm._e(),
          _vm.chat === 1
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.handleCancelMute(_vm.translateTitle("恢复私聊"))
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.translateTitle("恢复私聊")) + " ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleMoreInfo } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("更多资料")) + " ")]
          ),
        ],
        1
      ),
      _c("MuteDialog", {
        ref: "muteDialog",
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }