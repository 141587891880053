<template>
  <el-dialog
    append-to-body
    :before-close="handleClose"
    :title="translateTitle(title)"
    :visible.sync="dialogVisible"
    width="40%"
  >
    <div class="username">
      <el-image
        class="userImg"
        :preview-src-list="[user.head]"
        :src="user.head"
      />
      <span>{{ user.name }}</span>
    </div>
    <el-descriptions v-loading="loading" :column="2">
      <template v-for="(item, index) in infoColumnList">
        <el-descriptions-item
          v-if="item.prop !== 'tags'"
          :key="index"
          :label="translateTitle(item.label)"
        >
          {{ user[item.prop] }}
        </el-descriptions-item>
        <el-descriptions-item
          v-else
          :key="index"
          :label="translateTitle(item.label)"
        >
          <el-tag v-for="(el, idx) in user[item.prop]" :key="idx">
            {{ el.name }}
          </el-tag>
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <div slot="footer" class="dialog-footer">
      <el-button
        v-if="user.role === 0"
        :loading="btnLoading"
        type="primary"
        @click="handleUserMute(translateTitle('禁言'))"
      >
        {{ translateTitle('禁言') }}
      </el-button>
      <el-button
        v-if="user.role === 1"
        :loading="btnLoading"
        type="danger"
        @click="handleCancelMute(translateTitle('取消禁言'))"
      >
        {{ translateTitle('取消禁言') }}
      </el-button>
      <el-button
        v-if="chat === 0"
        :loading="btnLoading"
        type="primary"
        @click="handleUserMute(translateTitle('禁止私聊'))"
      >
        {{ translateTitle('禁止私聊') }}
      </el-button>
      <el-button
        v-if="chat === 1"
        :loading="btnLoading"
        type="danger"
        @click="handleCancelMute(translateTitle('恢复私聊'))"
      >
        {{ translateTitle('恢复私聊') }}
      </el-button>
      <el-button type="primary" @click="handleMoreInfo">
        {{ translateTitle('更多资料') }}
      </el-button>
    </div>
    <!-- 禁言-对话框 -->
    <MuteDialog ref="muteDialog" @getTableData="getTableData" />
  </el-dialog>
</template>

<script>
  import {
    getUserView,
    isbannedPrivateChat,
    cancleUserMute,
    cancleBatchUserBanned,
  } from '@/api/user'
  import { dateFormat } from '@/filters'
  import MuteDialog from './muteDialog'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      MuteDialog,
    },
    data() {
      return {
        dialogVisible: false,
        title: '用户信息',
        userInfo: {},
        user: {},
        chat: null,
        loading: false,
        btnLoading: false,
        infoColumnList: Object.freeze([
          {
            label: '用户ID',
            prop: 'user_id',
          },
          {
            label: '用户组',
            prop: 'group_name',
          },
          {
            label: '手机号',
            prop: 'phone',
          },
          {
            label: '登录次数',
            prop: 'login_count',
          },
          {
            label: '积分',
            prop: 'score',
          },
          {
            label: '生日',
            prop: 'birth',
          },
          {
            label: '注册时间',
            prop: 'regist_time',
          },
          {
            label: '注册IP',
            prop: 'regist_ip',
          },
          {
            label: '最后登录',
            prop: 'last_login_time',
          },
          {
            label: '最后登录IP',
            prop: 'last_login_ip',
          },
          {
            label: '用户标签',
            prop: 'tags',
          },
        ]),
      }
    },
    methods: {
      translateTitle,
      handleOpen(id) {
        // this.userInfo = row
        this.dialogVisible = true
        this.handleUserInfo(id)
        this.isbannedPrivateChat(id)
      },
      // 获取个人信息
      async handleUserInfo(id) {
        const params = {
          user_id: id,
        }
        this.loading = true
        const res = await getUserView(params)
        this.loading = false
        if (res.success) {
          res.data.extra.regist_time = res.data.extra.regist_time
            ? dateFormat(Number(res.data.extra.regist_time), true)
            : '无'
          res.data.extra.last_login_time = res.data.extra.last_login_time
            ? dateFormat(Number(res.data.extra.last_login_time), true)
            : '无'
          res.data.extra.tags = res.data.tags || []
          res.data.extra.user_id = res.data.user_id
          res.data.extra.group_name = res.data.group_name || '无'
          res.data.extra.regist_ip = res.data.extra.regist_ip || '无'
          res.data.extra.phone = res.data.phone || '无'
          res.data.extra.score = res.data.extra.score || '0'
          res.data.extra.birth = res.data.extra.birth || '--'
          res.data.extra.name = res.data.name
        }
        this.user = res.data.extra
      },
      // 查询是否被禁止私聊
      async isbannedPrivateChat(id) {
        const params = {
          id: id,
        }
        this.btnLoading = true
        const res = await isbannedPrivateChat(params)
        this.btnLoading = false
        if (res.success) {
          this.chat = res.data
        }
      },
      handleClose(done) {
        done()
        this.user = {}
      },
      // 禁言/禁止私聊
      handleUserMute(buttonName) {
        this.$refs.muteDialog.handleOpen(buttonName, this.user)
      },
      // 取消禁言/恢复私聊
      handleCancelMute(buttonName) {
        const params = { id: this.user.user_id }
        this.$confirm(`此操作将${buttonName}当前这条数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res
            if (buttonName === this.translateTitle('取消禁言')) {
              res = await cancleUserMute(params)
            } else {
              res = await cancleBatchUserBanned(params)
            }
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) {
              this.dialogVisible = false
              this.$emit('getTableData')
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 查询列表
      getTableData() {
        this.dialogVisible = false
        this.$emit('getTableData')
      },
      handleMoreInfo() {},
    },
  }
</script>
<style lang="scss" scoped>
  .username {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: bold;
    .userImg {
      width: 70px;
      height: 70px;
    }
    span {
      margin-left: 10px;
    }
  }
</style>
